<template>
  <div class="TaskList">
    <div>
      <el-card
        class="card-list"
      >
        <div
          class="create-delete-task"
        >
          <div>
            <el-checkbox
              v-model="isCheckedAll"
              :disabled="!tasksExists"
              :indeterminate="isIndeterminate"
              @change="checkAll"
            />
          </div>
          <div>
            <div
              v-if="!checkedTasks.length"
              class="actions full-width"
            >
              <el-button
                type="primary"
                plain
                @click="showEditor"
              >
                Create task
              </el-button>
            </div>
            <div v-else>
              <el-button
                type="danger"
                plain
                icon="el-icon-delete"
                @click="confirmDelete = true"
              >
                Delete
              </el-button>
            </div>
          </div>
        </div>
        <div>
          <TaskEditor
            v-if="addingTask"
            :adding-task="true"
            @add-task="addTask"
            @close-editor="closeEditor"
          />

          <div
            v-if="tasksExists"
            class="tasks-section"
          >
            <transition-group
              name="flip-list"
              tag="div"
            >
              <OneTask
                v-for="(task, index) in tasks.sortByDesc('createdAt').all()"
                :key="task.uuid"
                :task="task"
                :index="index"
                :tasks="tasks"
                :checked-tasks="checkedTasks"
                @check="checkTask"
                @delete="deleteTask"
              />
            </transition-group>
          </div>
          <div
            v-else-if="loading"
            class="well text-center"
          >
            Loading task list, please wait...
          </div>
          <div
            v-else-if="!tasksExists"
            class="well text-center"
          >
            No work time is recorded for this day.
          </div>
        </div>
        <el-dialog
          :visible.sync="confirmDelete"
          title="Delete tasks"
          width="30%"
        >
          <p>It will not be undone. Continue?</p>
          <span slot="footer">
            <el-button @click="confirmDelete = false">
              No
            </el-button>
            <el-button
              type="primary"
              @click="deleteTasks"
            >
              Yes
            </el-button>
          </span>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
import { Collection } from 'vue-oop';
import { showError } from '@/notification';

export default {
  name: 'TaskList',

  components: {
    TaskEditor: () => import('./TaskEditor.vue'),
    OneTask: () => import('./OneTask.vue'),
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    tasks: {
      type: Object,
      required: false,
      default: () => new Collection(),
    },
  },

  data: () => ({
    addingTask: false,
    time: null,
    checkedTasks: [],
    isCheckedAll: false,
    confirmDelete: false,
    isIndeterminate: false,
  }),

  computed: {
    tasksExists() {
      return this.tasks && this.tasks.isNotEmpty();
    },
  },

  methods: {
    async addTask(task) {
      const createdTask = await task.create();

      this.$emit('create', createdTask);
      this.closeEditor();
    },

    showEditor() {
      this.addingTask = true;
    },

    closeEditor() {
      this.addingTask = false;
    },

    checkTask(payload) {
      if (payload.value) {
        this.checkedTasks.push(payload.taskUuid);
      } else {
        this.checkedTasks = this.checkedTasks.filter(taskUuid => taskUuid !== payload.taskUuid);
      }

      if (this.checkedTasks.length === this.tasks.count()) {
        this.isCheckedAll = true;
        this.isIndeterminate = false;
      } else if (this.checkedTasks.length && this.checkedTasks.length < this.tasks.count()) {
        this.isIndeterminate = true;
        this.isCheckedAll = false;
      } else {
        this.isCheckedAll = false;
        this.isIndeterminate = false;
      }
    },

    checkAll(value) {
      this.checkedTasks = [];
      this.isIndeterminate = false;

      if (value) {
        this.tasks.map(task => {
          this.checkedTasks.push(task.uuid);

          return task;
        });
      }
    },

    async deleteTask(task) {
      try {
        await task.delete();

        this.$emit('delete', task);
      } catch (e) {
        console.error(e);
        showError(e.message);
      }
    },

    deleteTasks() {
      this.confirmDelete = false;
      this.$emit('delete-tasks', { uuids: this.checkedTasks });
      this.checkedTasks = [];
      this.isIndeterminate = false;
      this.isCheckedAll = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style
  lang="stylus"
  scoped
>

  .el-checkbox
    margin-top 10px

  .page-title
    padding 0
    font-size 28px
    line-height 33px
    display block
    margin 0 0 25px
    height 40px
    word-break break-all

  .well
    min-height 20px
    padding 19px
    margin 20px 0
    background-color #f5f5f5
    border 1px solid #f5f5f5
    border-radius 3px
    box-shadow none

  .task-enter-active
    transition opacity .5s

  .task-enter, .task-leave-to
    opacity 0

  .create-delete-task
    display grid
    grid-template 50px / auto auto
    justify-content start
    grid-gap 10px
    padding 20px 20px 10px 20px

  .flip-list-move
    transition transform 1s

  .OneTask:nth-child(even)
    background #fff

  .OneTask:nth-child(odd)
    background #f7f7f799

  .card-list::v-deep .el-card__body
    padding 0
</style>
